import React from "react"
import "../css/NoExtrasAvailable.css"

export default function NoExtrasAvailable() {
  return (
    <div className="no-extras__container">
      <div className="no-extras__box">
        <div className="no-extras__text">
          No Bonus Features Available To View*
        </div>
      </div>
    </div>
  )
}
