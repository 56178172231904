import React from "react"
import { Link } from "react-router-dom"
import type { Production } from "../globals/types"
import { colorSpinner } from "../globals/images"
import PageHeader from "../components/PageHeader"
import "../css/SelectProductionPage.css"
import qs from "qs"
import API from "../api"
import isEqual from "lodash/isEqual"
import uniqBy from "lodash/uniqBy"
import HorizontalScrollButtons from "../components/HorizontalScrollButtons"
import NoExtrasAvailable from "../components/NoExtrasAvailable"

type Props = {
  accountId: string,
  productions: Array<Production>,
  setHasBrowsed: () => void,
  loading: boolean,
}

type State = {
  filterText: string,
  scrollPosition: number,
  currentPage: string,
  currentSession: String,
}

type locationParams = {
  userId: string,
}

// prettier-ignore
class SelectProductionPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      filterText: "",
      productions: [],
      render: false,
      scrollPosition: 0,
      currentPage: "",
      currentSession:Date.now(),
    }

    this.handleProductionsByUserId = this.handleProductionsByUserId.bind(this)
    this.scrollLeft = this.scrollLeft.bind(this)
    this.scrollRight = this.scrollRight.bind(this)
  }

  scrollLeft () {
    const el = document.querySelector('#production-list-scroll')
    if (!el) return false
    const scrollTo = this.state.scrollPosition - 120 <= 0 ? 0 : this.state.scrollPosition - 120
    el.scrollBy({
      left: 700 - this.state.scrollPosition >= 0 ? - 1800 : 700 - this.state.scrollPosition,
      behavior: 'smooth'
    })
    this.setState(_ => ({ scrollPosition: scrollTo <= 0 ? 0 : scrollTo}))
  }
  flowEventAnalytic(movieTitle){
    if (!movieTitle) return false
    return API.flowEventAnalytics(movieTitle, false, this.state.currentSession)
  }
  scrollRight () {
    const el = document.querySelector('#production-list-scroll')
    if (!el) return false

    el.scrollBy({
      left: this.state.scrollPosition + 700,
      behavior: 'smooth'
    })
    const maxScrollable = el.getBoundingClientRect().width
    this.setState((prevState) => ({ scrollPosition: prevState.scrollPosition + 120 >= maxScrollable ? maxScrollable : prevState.scrollPosition + 120}))

    setTimeout(() => {
      // console.log(this.state.scrollPosition,el.getBoundingClientRect())
    }, 1000)
  }
 
  async handleProductionsByUserId (props) {
    const { location } = window
    const params : locationParams = qs.parse(location.search.replace(/[?]/g, ''))

    if (location.pathname === '/NBCUniversal' || location.pathname === '/NBCUniversal/') {
      this.setState({ productions: this.props.productions, render: true, currentPage: 'NBCUniversal' })
    } else {
      let { userId } = params;
      if(params.userid) userId = params.userid;
      if(params.uid) userId = params.uid;
      if (userId) {
        API.getProductionByUserId(userId).then(data => {
          const items = uniqBy([...data], e => e.id)
          let productions = props.map(production => {
            let push = false
            items.forEach(item => {
              if (item.name.toLowerCase() === production.title.toLowerCase()) push = item
            })
            if (push) return {
              ...production,
              logoUrl: `${push.settings.boxart  }.jpg`,
              eidr: push.settings.eidr
            }
            return false
          }).filter(i => !!i)
          productions = uniqBy(productions, e => e.title)
          this.setState({ productions, render: true, currentPage: 'allaccess' })
        }).catch(e => {
          this.setState({ productions: [], render: true, currentPage: 'allaccess' })
        })
      } else {
        this.setState({ productions: [], render: true })
      }
    }
  }

  componentDidMount() {
    this.props.setHasBrowsed()
  }

  componentDidUpdate(prevProps: Props, prevState:State,) {
    if (this.props.productions.length > 0) {

      if (isEqual(this.props.productions, prevProps.productions)) {
        if (this.state.render) return false
        
          this.handleProductionsByUserId(this.props.productions).then().catch(err => {
            console.error(err)
          })
        
      } else return false
    }
  }

  handleOnSearchChange = (e) => {
    this.setState({ filterText: e.target.value })
  }


  render() {
    const { accountId } = this.props

    // Filter productions by title text using current filter text.
    const filteredProductions = this.state.productions.filter(production => (
        production.title
          .toLowerCase()
          .indexOf(this.state.filterText.toLowerCase()) !== -1
      )
    )
    return (
      <div id="select-production-page" className="page open">
        <div
          className={!this.state.render ? "cover" : "cover hide"}
          style={{ backgroundImage: `url(${colorSpinner})` }}
        />
        <PageHeader title=" " />
        <div className="search-bar">
          <input
            id="production-filter"
            name="production-filter"
            placeholder="Search Movie or Title ..."
            onChange={this.handleOnSearchChange}
            value={this.state.filterText}
          />
        </div>
        {
          this.state.render && (
            <div
                className={this.state.currentPage.toLowerCase() === 'allaccess' ? 'production-list' : 'production-list grid-view'}
                id={this.state.currentPage.toLowerCase() === 'allaccess' ? 'production-list-scroll' : ''}
            >
              {filteredProductions.length > 0 ? (
                <>
                {this.state.scrollPosition !==0 && this.state.currentPage === 'allaccess' && <HorizontalScrollButtons type="left" click={this.scrollLeft} />}
                {this.state.scrollPosition <= 1092 * (filteredProductions.length / 10) && this.state.currentPage === 'allaccess' && <HorizontalScrollButtons type="right" click={this.scrollRight} />}
                {filteredProductions.map(production => (
                  <Link
                  key={production.id}
                  to={`/${accountId}/extras/${production.id}?sess=${this.state.currentSession}&from=${production.title}`}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    this.flowEventAnalytic(production.title)
                    window.parent.postMessage({ type: 'clicked', eidr: production.eidr}, '*')
                  }}
                  className="production-list-item-wrapper"
                  >
                    <div className={this.state.currentPage.toLowerCase() === 'allaccess' ? 'production-list-item' : 'production-list-item-nbc'}>
                      <div className={this.state.currentPage.toLowerCase() === 'allaccess' ? 'production-list-item__image' : 'production-list-item__image-nbc'}>
                        {production.logoUrl && <img src={production.logoUrl} alt={`${production.title} logo`} />}
                      </div>
                      <div className={this.state.currentPage.toLowerCase() === 'allaccess' ? 'production-list-item__info' : 'production-list-item__info-nbc'}>
                        <p>{production.title}</p>
                        <p className="production-synopsis">{production.synopsis}</p>
                      </div>
                    </div>
                  </Link>
                  )
                )}
                </>
              ) : (
                <NoExtrasAvailable />
              )}
            </div>
          )
        }
        
      </div>
    )
  }
}

export default SelectProductionPage
