// @flow

import React from "react"
import { Switch, Route } from "react-router-dom"
import type {
  Production,
  KnownDataType,
  DataType,
  DataObject,
} from "../globals/types"
import ExtrasPage from "../pages/ExtrasPage"
import ErrorPage from "../pages/ErrorPage"
import { categoryBlacklist } from "../globals/config"
import api from "../api"
import { knownDataTypes, contentTypes } from "../globals/globals"
import { colorSpinner } from "../globals/images"
import {
  mapImagesToProduction,
  compareBillingOrder,
  compareCategoryOrder,
  preloadImage,
  commaSplit,
} from "../globals/helpers"

type Props = {
  accountId: string,
  productionId?: string,
  eidr?: string,
  goBack: ?() => void,
  pulse: (data: {}) => void,
}

type State = {
  production: ?Production,
  loading: boolean,
  extras: { [dataTypeName: KnownDataType]: DataType },
  error: string | null,
}

class ExtrasPageContainer extends React.Component<Props, State> {
  state = {
    production: null,
    loading: true,
    extras: {},
    error: null,
  }

  componentDidMount() {
    this.loadProduction()
  }

  /** calls getProduction and getDataObjectsForProduction and loads results into state */
  loadProduction = async (): Promise<void> => {
    const { accountId, productionId, eidr } = this.props
    this.setLoading(true)

    try {
      if (productionId) {
        await this.loadProductionById(accountId, productionId)
      } else if (eidr) {
        await this.loadProductionByEidr(accountId, eidr)
      } else {
        throw Error("Missing Production ID and EIDR, can't display extras.")
      }
    } catch (error) {
      console.error("Error getting production", error, this.props)
      this.setState({ error })
      this.setLoading(false)
    }
  }

  setLoading = (loading: boolean): void => {
    this.setState({ loading })
  }

  /**
   * Fetches a production and its data objects in parallel,
   * then initializes extras.
   */
  loadProductionById = async (accountId: string, productionId: string) => {
    const [production, dataObjects] = await Promise.all([
      api.getProduction(accountId, productionId),
      api.getDataObjectsForProduction(accountId, productionId),
    ])

    // Map production urls to top-level properties
    const imageMappedProduction = mapImagesToProduction(production)
    if (!imageMappedProduction) {
      throw Error("No media on production")
    }

    this.setState({ production: imageMappedProduction })

    // Initialize raw data objects and process into Extras categories.
    this.initExtras(dataObjects)
  }

  /**
   * Fetch a production by its EIDR, then initialize extras.
   * Because we don't know production ID, have to wait for search to come back
   * before requesting data objects.
   */
  loadProductionByEidr = async (accountId: string, eidr: string) => {
    const production = await api.getProductionByEidr(accountId, eidr)
    if (!production) throw Error(`Couldn't find production for EIDR: ${eidr}`)

    // Map production urls to top-level properties
    const imageMappedProduction = mapImagesToProduction(production)
    if (!imageMappedProduction) {
      throw Error("No media on production")
    }

    this.setState({ production: imageMappedProduction })

    const dataObjects = await api.getDataObjectsForProduction(
      accountId,
      production.id,
    )
    this.initExtras(dataObjects)
  }

  initExtras = (dataObjects: Array<DataObject>): void => {
    const dataTypes = {}

    // Get character names first so we can set Cast Member's characterName
    // and billing order fields as we iterate.
    const characterNames = dataObjects.filter(
      dataObject => dataObject.dataTypeName === knownDataTypes.characterName,
    )

    // Iterate over all data objects, initializing those from supported types
    // (or any with a videoURL property).
    dataObjects.forEach(dataObject => {
      const { dataTypeName } = dataObject

      if (!dataTypeName) {
        console.warn("No dataTypeName for dataObject", dataObject)
        return
      }
      switch (dataTypeName) {
        case knownDataTypes.span:
          break
        case knownDataTypes.interactive: {
          const interactiveDataType = this.initOrRetrieveDataType(
            dataTypeName,
            dataObject,
            dataTypes,
          )

          const initializedDataObject = this.initDataObject(dataObject)
          if (!initializedDataObject) {
            console.warn("Error initializing dataObject, skipping")
            break
          }

          // Add this DO to its parent DT's dataObjects list.
          interactiveDataType.dataObjects.push(initializedDataObject)

          // Update the master dataTypes object with the updated dataType.
          dataTypes[dataTypeName] = interactiveDataType
          break
        }
        case knownDataTypes.castMember: {
          const castMemberDataType = this.initOrRetrieveDataType(
            dataTypeName,
            dataObject,
            dataTypes,
          )

          const initializedDataObject = this.initDataObject(dataObject)
          if (!initializedDataObject) {
            console.warn("Error initializing dataObject, skipping")
            break
          }

          // Set the Cast Member's character name if it doesn't have one.
          const data =
            !initializedDataObject.data.characterName &&
            this.getCharacterNameAndBillingOrderForCastMember(
              initializedDataObject.id,
              characterNames,
            )

          if (data && data.characterName) {
            initializedDataObject.data.characterName = data.characterName
          } else {
            console.warn(
              `Couldn't find character name for Cast Member`,
              initializedDataObject,
            )
          }

          if (data && typeof data.billingOrder === "number") {
            initializedDataObject.data.billingOrder = data.billingOrder
          } else {
            console.warn(
              `Couldn't find billing order for Cast Member`,
              initializedDataObject,
            )
          }

          // Add this DO to its parent DT's dataObjects list.
          castMemberDataType.dataObjects.push(initializedDataObject)

          // Update the master dataTypes object with the updated dataType.
          dataTypes[dataTypeName] = castMemberDataType
          break
        }
        case knownDataTypes.characterName: {
          const characterNameDataType = this.initOrRetrieveDataType(
            dataTypeName,
            dataObject,
            dataTypes,
          )

          characterNameDataType.dataObjects.push(dataObject)
          dataTypes[dataTypeName] = characterNameDataType
          break
        }
        case knownDataTypes.gallery:
        case knownDataTypes.galleries: {
          const galleryDataType = this.initOrRetrieveDataType(
            dataTypeName,
            dataObject,
            dataTypes,
          )
          const initializedDataObject = this.initDataObject(dataObject)
          if (!initializedDataObject) {
            console.warn("Error initializing dataObject, skipping")
            break
          }

          galleryDataType.dataObjects.push(initializedDataObject)
          dataTypes[dataTypeName] = galleryDataType
          break
        }
        case knownDataTypes.featuredContent: {
          const dataType = this.initOrRetrieveDataType(
            dataTypeName,
            dataObject,
            dataTypes,
          )
          const initializedDataObject = this.initDataObject(dataObject)
          if (!initializedDataObject) {
            console.warn("Error initializing dataObject, skipping")
            break
          }

          dataType.dataObjects.push(initializedDataObject)
          dataTypes[dataTypeName] = dataType
          break
        }
        default: {
          const initializedDataObject = this.initDataObject(dataObject)
          if (initializedDataObject) {
            const dataType = this.initOrRetrieveDataType(
              dataTypeName,
              initializedDataObject,
              dataTypes,
            )

            dataType.dataObjects.push(initializedDataObject)
            dataTypes[dataTypeName] = dataType
          }
        }
      }
    })
    // Finally, sort Cast Members by billing order before saving, and
    // pull out the first image as the category poster.
    const castMembers = dataTypes.castMember

    if (castMembers) {
      const sortedCastMembers = castMembers.dataObjects.sort(
        compareBillingOrder,
      )
      castMembers.dataObjects = sortedCastMembers
      castMembers.posterImageUrl = sortedCastMembers[0].mainImageUrl
      dataTypes.castMember = castMembers
    }

    // Remove Character Name from the extras list, as it's only used for
    // configuration.
    delete dataTypes[knownDataTypes.characterName]

    if (!dataTypes || Object.keys(dataTypes).length === 0) {
      this.setState({ error: "No extras" })
    } else {
      this.setState({ extras: dataTypes })
      this.getFeaturedContentReferences()
    }

    this.setLoading(false)
  }

  initOrRetrieveDataType = (
    dataTypeName: KnownDataType,
    dataObject: DataObject,
    dataTypes: { [dataTypeName: KnownDataType]: DataType },
  ) => {
    if (!dataTypes[dataTypeName]) {
      const newDataType: DataType = {
        id: dataObject.dataTypeId,
        name: dataObject.dataTypeName,
        displayName: dataObject.dataTypeDisplayName,
        posterImageUrl: dataObject.mainImageUrl,
        dataObjects: [],
        imageLoaded: false,
      }
      preloadImage(dataObject.mainImageUrl, () => {
        newDataType.imageLoaded = true
        this.setState(prevState => ({
          extras: {
            ...prevState.extras,
            [dataTypeName]: newDataType,
          },
        }))
      })

      // why are we updating the function parameter (dataTypes) if we don't return?
      /* eslint-disable-next-line */
      // dataTypes[dataTypeName] = newDataType

      return newDataType
    }

    /* eslint-disable-next-line */
    return dataTypes[dataTypeName]
  }

  // Initializes dataObject properties.
  initDataObject = (dataObject: DataObject) => {
    const initialized = dataObject

    if (!initialized.imageLoaded) {
      preloadImage(initialized.mainImageUrl, () => {
        initialized.imageLoaded = true
        this.updateDataObject(
          initialized.id,
          initialized.dataTypeName,
          initialized,
        )
      })
    }

    switch (initialized.dataTypeName) {
      case knownDataTypes.characterName:
      case knownDataTypes.span:
        return null
      case knownDataTypes.interactive:
        // Require interactives to have url
        if (!initialized.data || !initialized.data.url) {
          console.warn(
            "interactive data object missing url, skipping",
            initialized,
          )
          return null
        }
        initialized.contentType = contentTypes.interactive
        return initialized
      case knownDataTypes.castMember: {
        initialized.contentType = contentTypes.castMember
        preloadImage(initialized.mainImageUrl, () => {})

        const { data } = initialized
        if (!data) {
          console.warn("dataObject has no data in initDataObject", initialized)
          return initialized
        }

        // If DO is missing a fullName field, construct a fullName using firstName and/or lastName.
        if (!data.fullName) {
          const { firstName, lastName } = data
          let fullName = "Cast Member"
          if (firstName && lastName) fullName = `${firstName} ${lastName}`
          else if (firstName) fullName = firstName
          else if (lastName) fullName = lastName
          else
            console.warn(
              "No fullName, firstName, or lastName for castMember",
              dataObject,
            )
          data.fullName = fullName
          initialized.data = data
        }

        return initialized
      }
      case knownDataTypes.gallery:
      case knownDataTypes.galleries:
        // Set contentType
        initialized.contentType = contentTypes.gallery

        // Split comma-separated imageUrls into a list
        if (initialized.data && initialized.data.galleryImageUrls) {
          initialized.data.galleryImageList = commaSplit(
            initialized.data.galleryImageUrls,
          )
        } else {
          console.warn("Gallery missing galleryImageUrls", initialized)
          return null
        }

        // Split comma-separated imageUrls into a list
        if (initialized.data && initialized.data.galleryImageThumbnailUrls) {
          initialized.data.galleryImageThumbnailList = commaSplit(
            initialized.data.galleryImageThumbnailUrls,
          )
        } else {
          console.warn("Gallery missing galleryImageThumbnailUrls", initialized)
          return null
        }
        return initialized
      case knownDataTypes.featuredContent:
        if (initialized.data && initialized.data.videoURL) {
          initialized.contentType = contentTypes.videoClip
          initialized.data.watched = false
        }
        return initialized
      default:
        if (initialized.data && initialized.data.videoURL) {
          initialized.contentType = contentTypes.videoClip
          initialized.data.watched = false
          return initialized
        }

        console.log(
          `Unknown Data Type has no videoURL field, ignoring`,
          initialized.dataTypeName,
        )

        return null
    }
  }

  getCharacterNameAndBillingOrderForCastMember = (
    castMemberId: string,
    characterNames: Array<DataObject>,
  ): { characterName: ?string, billingOrder: ?number } => {
    const characterNameObject = characterNames.find(
      characterName => characterName.data.castMember === castMemberId,
    )
    if (characterNameObject) {
      return {
        characterName: characterNameObject.data.characterName,
        billingOrder: characterNameObject.data.billingOrder,
      }
    }
    console.warn(
      `Couldn't find characterNameObject for castMember with DO ID: ${castMemberId}`,
    )
    return { characterName: null, billingOrder: null }
  }

  // After we've fetched all the data objects for the production, use a '*reference' field on each
  // Featured Content data object to map our local copy of the D.O. to it.
  getFeaturedContentReferences() {
    const { featuredContent } = this.state.extras
    if (!featuredContent) return

    const { dataObjects } = featuredContent
    if (!dataObjects) {
      console.warn("Couldn't find featured content data objects")
      return
    }

    const updatedDataObjects = dataObjects.map(dataObject => {
      const referenceObject = this.referenceObjectForFeaturedContent(dataObject)
      if (referenceObject) {
        return this.mapReferenceObjectToFeaturedContent(
          referenceObject,
          dataObject,
        )
      }

      return dataObject
    })

    featuredContent.dataObjects = updatedDataObjects
    // Now that we have the reference object data, set the poster image for the featured content dataType.
    // Use the last object's image so we don't show the same image twice on the ExtrasPage
    featuredContent.posterImageUrl =
      dataObjects[dataObjects.length - 1].mainImageUrl
    preloadImage(featuredContent.posterImageUrl, () => {
      featuredContent.imageLoaded = true
      this.setState(prevState => ({
        extras: {
          ...prevState.extras,
          featuredContent,
        },
      }))

      this.setState(prevState => ({
        extras: {
          ...prevState.extras,
          featuredContent,
        },
      }))
    })
  }

  // Retrieves the reference object for a Featured Content DO from our local cache.
  referenceObjectForFeaturedContent(dataObject: DataObject) {
    // Get any fields including 'reference'.
    const fields = Object.keys(dataObject.data)
    const regex = /reference/gim
    const referenceFields = fields.filter(fieldName => fieldName.match(regex))
    const referenceFieldCount = referenceFields.length

    // If we found any fields for this dataObject that include 'reference' substring, check them for a value.
    if (referenceFieldCount > 0) {
      for (let i = 0; i < referenceFieldCount; i += 1) {
        const objectId = dataObject.data[referenceFields[i]]
        // If we find one, consider it this Featured Content DO's reference object id.
        if (objectId) {
          // Use this id to find the referenced object and add it to the main DO as '.referenceObject'
          const referenceObject = this.findDataObject(String(objectId))
          if (referenceObject) return referenceObject
        }
      }
    }

    console.warn("no reference object for Featured Content", dataObject)
    return null
  }

  mapReferenceObjectToFeaturedContent = (
    referenceObject: DataObject,
    featuredContentObject: DataObject,
  ) => {
    // Once we find the reference object, map its fields to the parent object so we can
    // use it like anything else.
    // Skip id,dataTypeId, dataType, dataTypeName, dataTypeDisplayName, name, and data.name.

    // Maintain the parent object's name.
    const namedReferenceObject = {
      ...featuredContentObject,
      name: featuredContentObject.name,
      data: { ...referenceObject.data, name: featuredContentObject.name },
    }
    // referenceObject.name = featuredContentObject.name
    // referenceObject.data.name = featuredContentObject.name

    const referenceObjectFieldNames = Object.keys(namedReferenceObject)
    const updatedFeaturedContent = featuredContentObject
    referenceObjectFieldNames.forEach(fieldName => {
      if (
        fieldName === "id" ||
        fieldName === "dataTypeId" ||
        fieldName === "dataType" ||
        fieldName === "dataTypeName" ||
        fieldName === "dataTypeDisplayName" ||
        fieldName === "name"
      ) {
        return
      }

      // $FlowFixMe
      updatedFeaturedContent[fieldName] = namedReferenceObject[fieldName]
    })
    return updatedFeaturedContent
  }

  findDataObject(id: string) {
    const dataTypes = this.state.extras
    const dataTypeNames = Object.keys(dataTypes)
    let found = null

    const dataTypeCount = dataTypeNames.length
    for (let i = 0; i < dataTypeCount; i += 1) {
      const dataType = dataTypes[dataTypeNames[i]]
      if (!dataType) {
        console.warn("Couldn't find ", dataType, dataTypes)
        // return
        break
      }
      const { dataObjects } = dataType
      if (!dataObjects) {
        console.warn("Couldn't find data objects for ", dataType)
        // return
        break
      }

      found = dataObjects.find(dataObject => dataObject.id === id)
      if (found) {
        break
      }
    }
    return found
  }

  updateDataObject = (
    id: string,
    dataTypeName: KnownDataType,
    updatedDataObject: DataObject,
  ) => {
    if (!id || !dataTypeName || !updatedDataObject) {
      console.warn(`Missing argument in updateDataObject`)
      return
    }

    const { extras } = this.state
    const dataType = extras[dataTypeName]
    if (!dataType) {
      console.warn("Couldn't find objects for dataType: ", dataTypeName)
      return
    }

    const indexToUpdate = dataType.dataObjects.findIndex(
      dataObject => dataObject.id === id,
    )
    if (indexToUpdate === -1) {
      console.warn(`Couldn't find '${dataTypeName}' with id '${id}'`)
      return
    }

    dataType.dataObjects[indexToUpdate] = updatedDataObject

    this.setState(prevState => ({
      extras: {
        ...prevState.extras,
        [dataTypeName]: dataType,
      },
    }))
  }

  render() {
    const { pulse, goBack } = this.props
    const { production, extras, error, loading } = this.state

    const title = production ? production.title : ""
    const orderedDataTypeNames = production
      ? production.MovieLabsOutOfMovieDataTypeNames
      : []

    // Flatten extras object into array of categories
    // and remove any blacklisted categories - see config.js for more
    const categories = Object.keys(extras)
      .map(categoryName => extras[categoryName])
      .filter(category => !categoryBlacklist.includes(category.displayName))
      .sort(compareCategoryOrder(orderedDataTypeNames))

    return !loading && (error || !categories || categories.length === 0) ? (
      <ErrorPage />
    ) : (
      <div>
        <div
          className={loading ? "cover" : "cover hide"}
          style={{ backgroundImage: `url(${colorSpinner})` }}
        />
        <Switch>
          <Route path="/:accountId/extras/:productionId/:dataTypeName/:dataObjectId">
            {/* <ExtrasPage title={title} categories={categories} /> */}
          </Route>
          <Route path="/:accountId/extras/:productionId/:dataTypeName">
            {/* <ExtrasPage title={title} categories={categories} /> */}
          </Route>
          <Route path="/:accountId/extras/:productionId?">
            <ExtrasPage
              title={title}
              categories={categories}
              headerLogoUrl={production ? production.logoUrl : ""}
              backgroundImageUrl={production ? production.appBackgroundUrl : ""}
              updateDataObject={this.updateDataObject}
              pulse={pulse}
              goBack={goBack}
            />
          </Route>
        </Switch>
      </div>
    )
  }
}

export default ExtrasPageContainer
