// @flow

import React from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import type { DataObject, Category } from "../globals/types"
import { knownDataTypes } from "../globals/globals"
import { castMembersCategoryPlaceholder } from "../globals/images"
import { getURLParameters } from "../globals/helpers"
import PageHeader from "../components/PageHeader"
import CastMembersPage from "./CastMembersPage"
import CategoryPage from "./CategoryPage"
import BackgroundBlurImage from "../components/BackgroundBlurImage"
import ScrollToTopButton from "../components/ScrollToTopButton"
import "../css/ExtrasPage.css"
import API from "../api"

/* Animation Helper */
// const Fade = ({ children, ...props }) => (
//   <CSSTransition {...props} timeout={1000} classNames="fade">
//     {children}
//   </CSSTransition>
// )

type Props = {
  title: string,
  headerLogoUrl: string,
  backgroundImageUrl: string,
  categories: Array<Category>,
  goBack: ?() => void,
  updateDataObject: (
    id: string,
    dataTypeName: string,
    updatedDataObject: DataObject,
  ) => void,
  pulse: (data: {}) => void,
}

type State = {
  activeCategory: ?Category,
  scrolled: boolean,
  listener: boolean,
  shouldAddDiv: boolean,
  currentSession: number,
  flowFrom: string,
}

class ExtrasPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.boxRef = React.createRef()

    this.state = {
      activeCategory: null,
      scrolled: false,
      listener: false,
      shouldAddDiv: false,
    }

    this.closeCategoryPage = this.closeCategoryPage.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidMount() {
    const param = getURLParameters(window.location.href)
    this.setState({ currentSession: param.sess, flowFrom: param.from })
  }

  componentDidUpdate() {
    const boxElement = document.getElementsByClassName("extras-grid")
    if (boxElement.length > 0 && !this.state.listener) {
      const [firstElement] = boxElement
      firstElement.addEventListener("scroll", e => {
        if (firstElement.scrollTop > 40) {
          this.setState({ scrolled: true })
        } else if (firstElement.scrollTop === 0) {
          this.setState({ scrolled: false })
        }
      })
      this.setState({ listener: true })
    }
  }
  // please Flow, re: bind
  closeCategoryPage: () => void

  closeCategoryPage() {
    const categoryPage = document.getElementById("category-page")
    if (!categoryPage) {
      console.warn("Couldn't find category page to close")
      return
    }

    // Animate out the CategoryPage and then remove the activeCategory
    categoryPage.classList.remove("open")
    window.setTimeout(() => this.setState({ activeCategory: null }), 400)
  }

  hide() {
    this.setState(() => ({
      scrolled: false,
      shouldAddDiv: true,
    }))
  }
  flowEventAnalytic(category) {
    if (!category) return false
    localStorage.setItem("from", category)
    return API.flowEventAnalytics(
      category,
      false,
      this.state.currentSession,
      32,
      this.state.flowFrom,
    )
  }

  render() {
    const { categories, goBack } = this.props
    const { activeCategory } = this.state

    if (!categories || categories.length === 0) return null

    // Get the castMembers category, if we have one, so we can preload the Actors page.
    const castMembersCategory = this.props.categories.find(
      category => category.name === knownDataTypes.castMember,
    )
    const castMembersList =
      castMembersCategory && castMembersCategory.dataObjects
        ? castMembersCategory.dataObjects
        : null
    const castMembersCategoryImage =
      (castMembersCategory && castMembersCategory.posterImageUrl) ||
      castMembersCategoryPlaceholder

    return (
      <div
        className="page open extras-page"
        style={{ backgroundImage: `url(${this.props.backgroundImageUrl})` }}
        id={"box-to-scroll"}
      >
        <div className="page__background-darken" />
        <PageHeader
          onBack={goBack}
          title={this.props.title}
          logoImageUrl={this.props.headerLogoUrl}
          pageName="Extras"
        />
        {this.state.shouldAddDiv && <div className={"extras-page-scroll"} />}
        <div className="page-body extras-page-body" id={"extras-page-scroll"}>
          {this.state.scrolled && (
            <ScrollToTopButton hide={this.hide} scrollTo={"grid-item"} />
          )}
          <TransitionGroup className="extras-grid">
            {this.props.categories.map(category => {
              if (category.imageLoaded)
                return (
                  <CSSTransition
                    key={category.name}
                    timeout={400}
                    classNames="fade"
                  >
                    <div
                      className="grid-item"
                      onClick={() => {
                        this.flowEventAnalytic(category.displayName)
                        this.setState({ activeCategory: category })
                      }}
                    >
                      <p>{category.displayName}</p>
                      <div className="grid-item__image">
                        <div className="grid-item__image__inner">
                          {category.name === knownDataTypes.castMember ? (
                            <BackgroundBlurImage
                              className="blurred-background-image__blur"
                              alt=""
                              src={castMembersCategoryImage}
                            />
                          ) : (
                            <BackgroundBlurImage
                              src={category.posterImageUrl}
                              alt={category.name}
                              className="grid-item__image__img"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                )
              return null
            })}
          </TransitionGroup>
        </div>
        {castMembersList ? (
          <CastMembersPage
            isOpen={
              activeCategory != null &&
              activeCategory.name === knownDataTypes.castMember
            }
            castMembersList={castMembersList}
            title={this.props.title}
            activeCategory={activeCategory}
            headerLogoUrl={this.props.headerLogoUrl}
            onClose={() => this.setState({ activeCategory: null })}
            backgroundImageUrl={this.props.backgroundImageUrl}
            pulse={this.props.pulse}
          />
        ) : null}
        <div
          id="category-page"
          className={
            activeCategory != null &&
            activeCategory.name !== knownDataTypes.castMember
              ? "page open"
              : "page"
          }
        >
          <CategoryPage
            category={activeCategory}
            onBack={this.closeCategoryPage}
            title={this.props.title}
            headerLogoUrl={this.props.headerLogoUrl}
            backgroundImageUrl={this.props.backgroundImageUrl}
            updateDataObject={this.props.updateDataObject}
            pulse={this.props.pulse}
            session={this.state.currentSession}
            from={this.state.flowFrom}
          />
        </div>
      </div>
    )
  }
}

export default ExtrasPage
