// @flow
import React from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Player, { PlaybackContext } from "@bit/sourcedigital.front-end.player"
import {
  Player as MobilePlayer,
  BigPlayButton,
  LoadingSpinner,
} from "video-react"
import { SegmentView } from "@bit/sourcedigital.front-end.media"
import Fullscreen from "@bit/sourcedigital.front-end.fullscreen"
import WithOrientation from "@bit/sourcedigital.front-end.with-orientation"
import type { DataObject, Category } from "../globals/types"
import { preloadImage } from "../globals/helpers"
import { isAndroid, isIOS, isWinPhone } from "react-device-detect"
import API from "../api"
import {
  pulseEventTypes,
  contentTypes,
  knownDataTypes,
  swiperPaginationTypes,
  deviceOrientations,
} from "../globals/globals"
import PageHeader from "../components/PageHeader"
import Gallery from "../components/Gallery"
import FeatureListItem from "../components/FeatureListItem"
import ResponsiveIFrame from "../components/ResponsiveIFrame"
import { OrientationContext } from "../components/OrientationProvider"
import "../css/CategoryPage.css"
import "../../node_modules/video-react/dist/video-react.css"
import { fullScreenWhite, exitFullScreenWhite } from "../globals/images"
import ScrollToTopButton from "../components/ScrollToTopButton"

type Props = {
  category: ?Category,
  onBack: () => void,
  title: string,
  headerLogoUrl: string,
  backgroundImageUrl: string,
  updateDataObject: (
    id: string,
    dataTypeName: string,
    updatedDataObject: DataObject,
  ) => void,
  pulse: (data: {}) => void,
  session: number,
  from: String,
}

type State = {
  selectedItemIndex: number,
  forcePlay: boolean,
  initialVideoPlayed: boolean,
  hasInteracted: boolean,
  isFullscreen: boolean,
  userRequestedFullscreen: boolean,
  interactiveStarted: boolean,
  scrolled: boolean,
  listener: boolean,
  shouldAddDiv: boolean,
}

class CategoryPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      selectedItemIndex: 0,
      forcePlay: false,
      initialVideoPlayed: false,
      hasInteracted: false,
      isFullscreen: false,
      userRequestedFullscreen: false,
      interactiveStarted: false,
      isDeviceTargeted: false,
      listener: false,
      scrolled: false,
      shouldAddDiv: false,
    }

    this.selectFeatureItem = this.selectFeatureItem.bind(this)
    this.didPlay = this.didPlay.bind(this)
    this.hideScrollBtn = this.hideScrollBtn.bind(this)
  }
  componentWillMount() {
    if (isAndroid || isIOS || isWinPhone) {
      this.setState({ isDeviceTargeted: true })
    }
  }
  componentDidUpdate(prevProps: Props) {
    // If we update and no longer have a category, reset the selectedItemIndex.
    if (this.props.category == null && this.state.selectedItemIndex !== 0) {
      this.setState({ selectedItemIndex: 0 })
      return
    }

    // If we change categories, reset the selectedItemIndex and ensure all images load (race conditions may prevent
    // featuredContent DOs from getting their imageLoaded property set).
    // was: if (this.props.category != null...
    const { category } = this.props
    if (category && prevProps.category == null) {
      this.setState({ selectedItemIndex: 0 })
      category.dataObjects.forEach(dataObject => {
        if (!dataObject.imageLoaded) {
          preloadImage(dataObject.mainImageUrl, () => {
            const updated = { ...dataObject, imageLoaded: true }
            this.props.updateDataObject(
              updated.id,
              updated.dataTypeName,
              updated,
            )
          })
        }
      })
    }

    const list = document.getElementsByClassName("category-page__list")
    if (list.length > 0 && !this.state.listener) {
      const [item] = list
      item.addEventListener("scroll", e => {
        if (item.scrollTop > 40) {
          this.setState({ scrolled: true })
        } else if (item.scrollTop === 0) {
          this.setState({ scrolled: false })
        }
      })
      this.setState({ listener: true })
    }
  }

  // please Flow, re: bind
  selectFeatureItem: (id: string) => void
  didPlay: () => void

  reset() {
    this.setState({
      selectedItemIndex: 0,
      forcePlay: false,
      initialVideoPlayed: false,
      hasInteracted: false,
    })
  }

  selectFeatureItem(id: string) {
    const { category } = this.props
    if (!category) {
      console.warn("No category when selecting feature item", id)
      return
    }

    const selectedItemIndex = category.dataObjects.findIndex(
      item => item.id === id,
    )
    if (selectedItemIndex === -1) {
      console.warn("Couldn't find feature item with id: ", id)
      return
    }

    const selectedItem = category.dataObjects[selectedItemIndex]

    // Log the interaction with 'pulse' (Source's analytics api)
    this.props.pulse({
      dataObjectId: selectedItem.id,
      type: pulseEventTypes.interaction,
    })
    // auto trigger fullscreen on index change
    if (this.state.isFullscreen) {
      if (selectedItem.contentType === contentTypes.videoClip) {
        // add half a second delay for videos
        setTimeout(() => {
          this.handleFullscreenChange(true)
        }, 500)
      } else this.handleFullscreenChange(true)
    }
    this.setState({
      selectedItemIndex,
      forcePlay: selectedItem.contentType === contentTypes.videoClip,
      hasInteracted: true,
      interactiveStarted: true,
    })
  }

  handleStartInteractive = () => {
    this.setState({ interactiveStarted: true })
    this.handleFullscreenChange(true)
  }

  handleCloseInteractive = () => {
    this.setState({ interactiveStarted: false })
    this.handleFullscreenChange(false)
  }

  handleRequestPlay = () => {
    const { initialVideoPlayed } = this.state
    if (!initialVideoPlayed) {
      this.setState({ initialVideoPlayed: true })
      this.handleFullscreenChange(true)
    }
  }

  didPlay() {
    const { category } = this.props
    if (!category) {
      console.warn("No category in didPlay, can't find item to set 'watched'")
      return
    }

    // When a video clip plays, set it to watched.
    const selectedItem = category.dataObjects[this.state.selectedItemIndex]
    if (!selectedItem) {
      console.warn("Couldn't find selected item to set 'watched'")
      return
    }

    // Only log plays for playing the initially loaded video the first time.
    // Other interactions are tracked by clicking a FeatureListItem.
    if (this.state.selectedItemIndex === 0 && !this.state.initialVideoPlayed) {
      this.props.pulse({
        dataObjectId: selectedItem.id,
        type: pulseEventTypes.interaction,
      })
    }

    if (
      selectedItem.contentType === contentTypes.videoClip &&
      !selectedItem.data.watched
    ) {
      selectedItem.data.watched = true
      this.props.updateDataObject(selectedItem.id, category.name, selectedItem)
    }

    if (!this.state.hasInteracted) this.setState({ hasInteracted: true })
  }

  /** Used when a component requests fullscreen status to change, generically */
  handleRequestFullscreenChange = (): void => {
    this.setState(prevState => ({
      isFullscreen: !prevState.isFullscreen,
      userRequestedFullscreen: !prevState.isFullscreen,
    }))
  }

  /** Used when next fullscreen state is specified */
  handleFullscreenChange = (fullscreenStatus: boolean): void => {
    // toggle current state if value is undefined, otherwise use received value
    this.setState(prevState => {
      const value =
        fullscreenStatus === undefined
          ? !prevState.isFullscreen
          : fullscreenStatus
      return {
        isFullscreen: value,
        userRequestedFullscreen: value,
      }
    })
  }

  logPause = (context: PlaybackContext) => {
    const { category } = this.props
    if (!category) {
      console.error(
        "missing category when trying to log segment view",
        category,
        context,
      )
      return
    }
    const selectedItem = category.dataObjects[this.state.selectedItemIndex]

    this.props.pulse({
      dataObjectId: selectedItem.id,
      type: pulseEventTypes.interaction,
      event: "pause",
      ...context,
    })
  }

  logSegmentView = ({
    segmentView,
    context,
  }: {
    segmentView: SegmentView,
    context: PlaybackContext,
  }) => {
    const { category } = this.props
    if (!category) {
      console.error(
        "missing category when trying to log segment view",
        category,
        segmentView,
        context,
      )
      return
    }

    const selectedItem = category.dataObjects[this.state.selectedItemIndex]

    this.props.pulse({
      dataObjectId: selectedItem.id,
      type: pulseEventTypes.view,
      event: "segmentView",
      ...segmentView,
      ...context,
    })
  }

  hideScrollBtn = () => {
    this.setState({ scrolled: false, shouldAddDiv: true })
  }

  flowEventAnalytic(category) {
    if (!category) return false
    API.flowEventAnalytics(
      category,
      true,
      this.props.session,
      32,
      localStorage.getItem("from"),
    )
  }

  render() {
    const { category } = this.props
    const {
      isFullscreen,
      userRequestedFullscreen,
      selectedItemIndex,
      interactiveStarted,
    } = this.state
    if (!category || category.name === knownDataTypes.castMember) return null

    const selectedItem = category.dataObjects[selectedItemIndex]
    const isLandscape =
      this.context.orientation === deviceOrientations.landscapeLeft ||
      this.context.orientation === deviceOrientations.landscapeRight
    return (
      <div
        className="category-page page open"
        style={{ backgroundImage: `url(${this.props.backgroundImageUrl})` }}
      >
        <div className="page__background-darken" />
        {/* onBack included argument 'e', but I think it's unused */}
        <PageHeader
          onBack={() => {
            this.reset()
            this.props.onBack()
          }}
          title={this.props.title}
          logoImageUrl={this.props.headerLogoUrl}
          pageName={category ? category.displayName : ""}
        />
        <div className="page-body">
          {this.state.scrolled && (
            <ScrollToTopButton
              hide={this.hideScrollBtn}
              scrollTo={"feature-list-item"}
            />
          )}
          <div
            className="category-page__content"
            onClick={() => {
              this.flowEventAnalytic(selectedItem.name)
            }}
          >
            <WithOrientation
              render={orientation => (
                <Fullscreen
                  childId="sd-category-page__main"
                  active={isFullscreen}
                  onChange={(fullscreenStatus: boolean): void => {
                    this.setState({ isFullscreen: fullscreenStatus })
                  }}
                  orientationTrigger={{
                    orientation,
                    userRequestedFullscreen,
                  }}
                  forceUpdate={() => this.forceUpdate()}
                >
                  <div
                    id="sd-category-page__main"
                    className={`category-page__main ${
                      isFullscreen ? "category-page__main--fs" : ""
                    }`}
                  >
                    <div className="category-page__main__inner">
                      {selectedItem.contentType === contentTypes.videoClip &&
                        (this.state.isDeviceTargeted ? (
                          <MobilePlayer
                            src={selectedItem.data.videoURL}
                            poster={selectedItem.mainImageUrl}
                          >
                            <BigPlayButton position="center" />
                            <LoadingSpinner />
                          </MobilePlayer>
                        ) : (
                          <Player
                            source={{
                              type: "http",
                              url: selectedItem.data.videoURL,
                              poster: selectedItem.mainImageUrl,
                            }}
                            isFullscreen={isFullscreen}
                            onError={() =>
                              alert(
                                "Sorry, we're having trouble playing this title right now.",
                              )
                            }
                            onRequestPlay={this.handleRequestPlay}
                            playing={this.state.forcePlay}
                            onPlaying={this.didPlay}
                            log={{
                              pause: this.logPause,
                              segmentView: this.logSegmentView,
                            }}
                            onRequestFullscreenChange={
                              this.handleFullscreenChange
                            }
                          />
                        ))}
                      {selectedItem.contentType === contentTypes.gallery && (
                        <Gallery
                          id={`gallery-${selectedItem.id}`}
                          dataObjectId={selectedItem.id}
                          imageUrls={
                            window.innerWidth > 480 || isLandscape
                              ? selectedItem.data.galleryImageList
                              : selectedItem.data.galleryImageThumbnailList
                          }
                          orientation={orientation}
                          paginationType={swiperPaginationTypes.fraction}
                          onSlideChange={slideIndex => {
                            // Log the interaction with 'pulse' (Source's analytics api)
                            this.props.pulse({
                              dataObjectId: selectedItem.id,
                              type: pulseEventTypes.view,
                              slideIndex,
                            })
                          }}
                          pulse={this.props.pulse}
                          handleFullscreenChange={this.handleFullscreenChange}
                          isFullscreen={isFullscreen}
                          albumIndex={selectedItemIndex}
                        />
                      )}
                      {selectedItem.contentType ===
                        contentTypes.interactive && (
                        <ResponsiveIFrame
                          poster={
                            interactiveStarted
                              ? null
                              : selectedItem.mainImageUrl
                          }
                          src={selectedItem.data.url}
                          title={selectedItem.data.name || "Interactive"}
                          onStart={this.handleStartInteractive}
                          onClose={this.handleCloseInteractive}
                        />
                      )}
                    </div>
                  </div>
                </Fullscreen>
              )}
            />
            {selectedItem.contentType === contentTypes.interactive &&
              !isFullscreen && (
                <div className="interactives-toolbar-holder">
                  <div className="toolbar-shadow" />
                  <div
                    className="fullscreen-btn"
                    onClick={() => {
                      this.handleFullscreenChange(!isFullscreen)
                    }}
                  >
                    <img
                      src={isFullscreen ? exitFullScreenWhite : fullScreenWhite}
                      alt="fullscreen toggle"
                    />
                  </div>
                </div>
              )}
            <div className="category-page__detail">
              <p className="category-page__detail__name">{selectedItem.name}</p>
            </div>
          </div>
          <TransitionGroup className="category-page__list">
            {category
              ? category.dataObjects.map((featureItem, index) => {
                  if (featureItem.imageLoaded) {
                    return (
                      <CSSTransition
                        key={featureItem.id}
                        timeout={400}
                        classNames="fade category-page__list-item"
                      >
                        <FeatureListItem
                          featureItem={featureItem}
                          selected={selectedItemIndex === index}
                          selectItem={this.selectFeatureItem}
                          forceTimestamp={
                            index === 0 && !this.state.hasInteracted
                          }
                          session={this.props.session}
                          from={this.props.from}
                        />
                      </CSSTransition>
                    )
                  }
                  return null
                })
              : null}
          </TransitionGroup>
        </div>
      </div>
    )
  }
}

// $FlowFixMe
CategoryPage.contextType = OrientationContext

export default CategoryPage
