// @flow

import React from "react"
import { contentTypes } from "../globals/globals"
import { toTimeString } from "../globals/helpers"
import type { DataObject } from "../globals/types"
import { playButton } from "../globals/images"
import API from "../api"

type Props = {
  featureItem: DataObject,
  selectItem: (itemId: string) => void,
  selected?: boolean,
  forceTimestamp?: boolean,
  session?: number,
  from?: String,
}

const FeatureListItem = (props: Props) => {
  const { featureItem, selected, forceTimestamp, selectItem } = props

  const clipPosterInfoForItem = (item: DataObject) => {
    if (item.contentType !== contentTypes.videoClip) return ""

    if (selected && !forceTimestamp) return "Playing"
    if (!selected && item.data.watched) return "Watched"
    return item.data.actualDuration
      ? toTimeString(item.data.actualDuration)
      : "0:00"
  }

  if (!featureItem) {
    console.warn("No feature item prop")
    return null
  }

  const clipPosterInfo = clipPosterInfoForItem(featureItem)
  const { summary } = featureItem.data

  const showPlayButton =
    featureItem.contentType === contentTypes.videoClip ||
    featureItem.contentType === contentTypes.interactive

  const flowEventAnalytic = featureItem => {
    if (!featureItem) return false
    return API.flowEventAnalytics(
      featureItem,
      true,
      props.session,
      32,
      localStorage.getItem("from"),
    )
  }

  return (
    <div
      className={selected ? "feature-list-item selected" : "feature-list-item"}
      onClick={() => {
        selectItem(featureItem.id)
        flowEventAnalytic(featureItem.name)
      }}
    >
      <div className="feature-list-item__poster">
        <img src={featureItem.mainImageUrl} alt="" />
        {showPlayButton && (
          <div>
            <img className="clip-poster__play" src={playButton} alt="play" />
            <p className="clip-poster__info">{clipPosterInfo}</p>
          </div>
        )}
      </div>
      <section className="feature-list-item__info">
        <p className="feature-list-item__info-name">{featureItem.name}</p>
        {summary && (
          <p className="feature-list-item__info-description">{summary}</p>
        )}
      </section>
    </div>
  )
}

FeatureListItem.defaultProps = {
  selected: false,
  forceTimestamp: false,
}

export default FeatureListItem
