// @flow

import React from "react"
import "../css/ResponsiveIFrame.css"
import { playButton } from "../globals/images"

type Props = {
  src: ?string,
  title: string,
  poster: ?string,
  onStart: () => void,
  onClose: () => void,
}

type MessageEventHandler = (event: MessageEvent) => mixed

class ResponsiveIFrame extends React.Component<Props> {
  componentDidMount() {
    window.addEventListener("message", this.handleReceivedMessage)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleReceivedMessage)
  }

  handleReceivedMessage = (e: MessageEventHandler) => {
    const { onClose } = this.props
    if (e.data && e.data.event) {
      switch (e.data.event) {
        case "AppVisible":
          console.log("Hello Extras: received AppVisible")
          e.source.postMessage("ack_skinnyapp_presence", "*")
          console.log(`Hello Extras: sent ack_skinnyapp_presence`)
          break
        case "AppShutdown":
          console.log("Hello Extras: received AppShutdown")
          onClose()
          break
        default:
          return null
      }
    }
    return null
  }

  render() {
    const { src, title, poster, onStart } = this.props
    return (
      <div className="responsive-iframe-container">
        {poster ? (
          <div className="iframe-poster" onClick={onStart}>
            <img
              className="iframe-poster__poster"
              src={poster}
              alt="interactive poster"
            />
            <img className="iframe-poster__play" src={playButton} alt="play" />
          </div>
        ) : src ? (
          <iframe width="100%" scrolling="no" src={src} title={title} />
        ) : (
          <p>Application not found</p>
        )}
      </div>
    )
  }
}

export default ResponsiveIFrame
