// @flow

import React from "react"
import { compareBillingOrder } from "../globals/helpers"
import type { SyncedCastMembers, DataObject } from "../globals/types"

type Props = {
  syncedCastMembers?: SyncedCastMembers,
  castMembersList?: Array<DataObject>,
  selectCastMember: (timelineSegmentId: string) => void,
  selectedCastMemberIndex: number,
  lightBackground: boolean,
  forceShowAll: boolean,
}

class ThumbnailList extends React.Component<Props, { showMore: boolean }> {
  static defaultProps = {
    syncedCastMembers: undefined,
    castMembersList: undefined,
  }

  state = {
    showMore: false,
  }

  getCastMembers() {
    if (this.props.castMembersList) {
      // castMembersList items are Data Objects
      return this.props.castMembersList
    }

    if (this.props.syncedCastMembers) {
      // syncedCastMembers are Timeline segments
      return this.state.showMore || this.props.forceShowAll
        ? this.props.syncedCastMembers.all
        : this.props.syncedCastMembers.active
    }

    console.warn("ThumbnailList didn't receive any cast members")
    return []
  }

  render() {
    // ThumbnailList may be passed a castMembers object {all: [...], active: [...]} or simply an Array of cast members.
    const castMembers = this.getCastMembers().sort(compareBillingOrder)

    const { selectedCastMemberIndex } = this.props

    return (
      <div
        className={
          this.props.lightBackground
            ? "thumbnail-list-container"
            : "thumbnail-list-container dark"
        }
      >
        <div className="thumbnail-list">
          <p>ACTORS</p>
          <div className="thumbnails">
            {castMembers.map((castMember: DataObject, index) => (
              <div
                key={castMember.id}
                className={
                  index === selectedCastMemberIndex
                    ? "thumbnail selected"
                    : "thumbnail"
                }
                onClick={() => this.props.selectCastMember(castMember.id)}
              >
                <div className="thumbnail__image selected">
                  {castMember.data.fullName.slice(0, 1)}
                  {// Only show images if they're loaded
                  castMember.mainImageUrl && castMember.imageLoaded ? (
                    <img src={castMember.mainImageUrl} alt={castMember.name} />
                  ) : null}
                </div>
                <p className="thumbnail__name">{castMember.data.fullName}</p>
                <p className="thumbnail__character">
                  {castMember.data.characterName}
                </p>
              </div>
            ))}
          </div>
          {!this.state.showMore && !this.props.forceShowAll ? (
            <div
              className="show-more"
              onClick={() =>
                this.setState(prevState => ({ showMore: !prevState.showMore }))
              }
            >
              Show More
            </div>
          ) : null}
        </div>
        {this.state.showMore && !this.props.forceShowAll ? (
          <div
            className="show-less"
            onClick={() =>
              this.setState(prevState => ({ showMore: !prevState.showMore }))
            }
          >
            <p>Show Less</p>
          </div>
        ) : null}
      </div>
    )
  }
}

export default ThumbnailList
