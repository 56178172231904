// @flow

import React from "react"
import { wrapGrid } from "animate-css-grid"
import type { DataObject, Category } from "../globals/types"
import { pulseEventTypes, knownDataTypes } from "../globals/globals"
import PageHeader from "../components/PageHeader"
import ThumbnailList from "../components/ThumbnailList"
import Card from "../components/Card"
import "../css/CastMembersPage.css"

type Props = {
  castMembersList: Array<DataObject>,
  activeCategory: ?Category,
  isOpen: boolean,
  onClose: () => void,
  title: string,
  headerLogoUrl: string,
  backgroundImageUrl: string,
  pulse: (data: {}) => void,
}

type State = {
  selectedCastMemberIndex: number,
  mode: "list" | "grid",
}

class CastMembersPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.minGridWidth = 768

    this.state = {
      selectedCastMemberIndex: 0,
      mode: window.innerWidth >= this.minGridWidth ? "grid" : "list",
    }
  }

  unwrapGrid: ?() => void
  minGridWidth: number

  componentDidMount() {
    // Check for grid and wrap it for animations
    this.initializeGrid()

    window.onresize = this.onResize
  }

  componentWillUnmount() {
    if (this.unwrapGrid) this.unwrapGrid()
    window.onresize = null
  }

  onResize = () => {
    const { mode } = this.state
    const width = window.innerWidth
    // Check if we should change modes (list view vs. grid view)
    if (mode === "grid" && width < this.minGridWidth) {
      if (this.unwrapGrid) {
        this.unwrapGrid()
        this.unwrapGrid = null
      }
      this.setState({ mode: "list" })
    } else if (mode === "list" && width >= this.minGridWidth) {
      this.setState({ mode: "grid" }, this.initializeGrid)
    }
  }

  initializeGrid() {
    const grid = document.querySelector(".card-grid")
    if (grid) {
      const { unwrapGrid, forceGridAnimation } = wrapGrid(grid, {
        duration: 400,
        stagger: 10,
      })

      this.unwrapGrid = unwrapGrid

      return { unwrapGrid, forceGridAnimation }
    }

    return null
  }

  selectCastMember = (id: ?string) => {
    const selectedCastMemberIndex = this.props.castMembersList.findIndex(
      castMember => castMember.id === id || castMember.timelineSegmentId === id,
    )
    if (selectedCastMemberIndex === -1) {
      console.warn("Couldn't find cast member with id: ", id)
      return
    }

    // Log the interaction with 'pulse' (Source's analytics api)
    const selectedCastMember = this.props.castMembersList[
      selectedCastMemberIndex
    ]
    this.props.pulse({
      dataObjectId: selectedCastMember.id,
      type: pulseEventTypes.interaction,
    })

    // Scroll to top of card body
    const cardBody = document.querySelector(".card__body")
    // $FlowFixMe
    if (cardBody) cardBody.scrollTo(0, 0)

    this.setState({ selectedCastMemberIndex })
  }

  render() {
    const selectedCastMember = this.props.castMembersList[
      this.state.selectedCastMemberIndex
    ]

    const selectedCastMemberBio = selectedCastMember.data.bio
    const selectedCastMemberImage = selectedCastMember.mainImageUrl

    const { activeCategory } = this.props
    const pageName =
      activeCategory && activeCategory.name === knownDataTypes.castMember
        ? activeCategory.displayName
        : "Actors"

    return (
      <div
        id="actors-page"
        className={
          this.props.isOpen ? "page open background-img" : "page background-img"
        }
        style={{ backgroundImage: `url(${this.props.backgroundImageUrl})` }}
      >
        <div className="page__background-darken" />
        <PageHeader
          onBack={this.props.onClose}
          title={this.props.title}
          logoImageUrl={this.props.headerLogoUrl}
          pageName={pageName}
        />
        {this.state.mode === "list" ? (
          <div className="actors-page-content">
            <div className="actors-page-content__list">
              <ThumbnailList
                castMembersList={this.props.castMembersList}
                selectCastMember={this.selectCastMember}
                selectedCastMemberIndex={this.state.selectedCastMemberIndex}
                lightBackground
                forceShowAll
              />
            </div>
            <div className="actors-page-content__detail">
              <Card
                image={selectedCastMemberImage}
                body={selectedCastMemberBio}
                containerClassName="simple-detail-card"
              />
            </div>
          </div>
        ) : (
          <div className="card-grid">
            {this.props.castMembersList.map(castMember => {
              const isSelected = selectedCastMember.id === castMember.id
              return (
                <div
                  key={castMember.id}
                  className={isSelected ? "detail-card" : ""}
                >
                  <Card
                    id={castMember.id}
                    containerClassName="cast-member-card"
                    image={castMember.mainImageUrl}
                    title={castMember.data.fullName}
                    subtitle={castMember.data.characterName}
                    body={isSelected ? castMember.data.bio : null}
                    onClick={this.selectCastMember}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default CastMembersPage
