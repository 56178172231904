// @flow
import * as React from "react"
import "../css/Card.css"

type Props = {
  id?: string, // passed as argument in onClick
  image?: string,
  title?: string,
  subtitle?: ?string,
  body?: ?string,
  containerClassName?: string,
  children?: React.Node,
  onClick?: (id: ?string) => void,
}

const Card = (props: Props) => {
  const {
    id,
    image,
    title,
    subtitle,
    body,
    containerClassName,
    children,
    onClick,
  } = props

  const handleClick = () => {
    if (onClick) onClick(id)
  }

  return (
    <div className={`card ${containerClassName || ""}`} onClick={handleClick}>
      <div className="card__header">
        {image && (
          <img className="card__header__image" src={image} alt={title} />
        )}
        {(title || subtitle) && (
          <div className="card__header__info">
            {title && <p className="card-info__title">{title}</p>}
            {subtitle && <p className="card-info__subtitle">{subtitle}</p>}
          </div>
        )}
      </div>
      {body && <p className="card__body">{body}</p>}
      {children && <div className="card__details">{children}</div>}
    </div>
  )
}

export default Card
