// @flow

import React from "react"
import BackButton from "./BackButton"
// import Image from "./Image"
import "../css/PageHeader.css"

type Props = {
  onBack?: ?() => void,
  title?: string,
  pageName?: string,
  logoImageUrl?: string,
}

const PageHeader = (props: Props) => {
  // const { onBack, title, logoImageUrl, pageName } = props
  const { onBack, pageName } = props

  return (
    <div className="page-header">
      <div className="page-header__back">
        {onBack && <BackButton onBack={onBack} />}
      </div>
      {/* // Background Title Image
      <Image
        src={logoImageUrl}
        alt={`${title || ""} title treatment`}
        fallbackText={title}
        className="page-header__logo"
        fallbackClassName="page-header__title"
      /> 
      */}
      <p className="page-header__name">{pageName}</p>
    </div>
  )
}

export default PageHeader
