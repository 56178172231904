// @flow

import React from "react"
import { Route, Switch, withRouter } from "react-router-dom"
import { experienceId } from "../globals/config"
import type { Production } from "../globals/types"
import { mapImagesToProduction } from "../globals/helpers"
import api from "../api"
import { OrientationContext } from "./OrientationProvider"
import ExtrasPageContainer from "../containers/ExtrasPageContainer"
import SelectProductionPage from "../pages/SelectProductionPage"
import ErrorPage from "../pages/ErrorPage"
import "../css/main.css"

type AppProps = {
  accountId: string,
  seriesId?: string,
  productionId?: string,
  eidr?: string,
  location: {
    search: string,
    pathname: string,
  },
  history: {
    push: (newRoute: string) => void,
    goBack: () => void,
  },
}

type AppState = {
  productions: Array<Production>,
  loading: boolean,
  hasBrowsed: boolean,
  error: boolean,
}

class App extends React.Component<AppProps, AppState> {
  state = {
    productions: [],
    loading: true,
    hasBrowsed: false,
    error: false,
  }

  pulse = this.pulse.bind(this)

  componentDidMount() {
    this.getProductions()
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading })
  }

  setHasBrowsed = () => {
    this.setState({ hasBrowsed: true })
  }

  getProductions = async () => {
    const { accountId, seriesId } = this.props
    this.setLoading(true)

    try {
      const productions = await api.getProductions(accountId, seriesId)
      const imageMappedProductions = productions
        .map(production => mapImagesToProduction(production))
        .filter(production => !!production)

      if (imageMappedProductions && imageMappedProductions.length > 0) {
        // $FlowFixMe | Flow can't figure out that imageMappedProductions will never have null entities
        this.setState({ productions: imageMappedProductions, error: false })
      } else {
        this.setState({ error: true })
      }
    } catch (error) {
      console.error("Error getting productions", error)
      this.setState({ error: true })
    } finally {
      this.setLoading(false)
    }
  }

  async pulse(data: {}) {
    const { accountId, productionId } = this.props

    if (!data) {
      console.warn("No data passed to pulse, can't log.", data)
      return
    }

    const wrappedData = {
      ...data,
      productionId,
      eventDateTime: new Date().toUTCString(),
      experienceId,
    }

    try {
      api.pulse(accountId, wrappedData)
    } catch (error) {
      console.error("Error trying to pulse", wrappedData, error)
    }
  }

  render() {
    const { accountId, productionId, eidr, history } = this.props
    const { loading, hasBrowsed, error } = this.state

    return (
      <div id="app-container">
        {error ? (
          <ErrorPage />
        ) : (
          <div>
            <Switch>
              <Route path="/:accountId/extras/:productionId?">
                <ExtrasPageContainer
                  accountId={accountId}
                  productionId={productionId}
                  eidr={eidr}
                  goBack={hasBrowsed ? history.goBack : null}
                  pulse={this.pulse}
                />
              </Route>
              <Route path="/:accountId/:seriesId?">
                <SelectProductionPage
                  accountId={accountId}
                  productions={this.state.productions}
                  setHasBrowsed={this.setHasBrowsed}
                  loading={loading}
                />
              </Route>
            </Switch>
          </div>
        )}
      </div>
    )
  }
}

// $FlowFixMe
App.contextType = OrientationContext

export default withRouter(App)
