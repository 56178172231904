// @flow

import homeIconWhiteLocal from "../assets/icons/home-icon-white.png"
import loadingSpinnerBlackBgLocal from "../assets/loading-spinner-black-bg.gif"
import colorSpinnerLocal from "../assets/sync-spinner.svg"
import leftArrowWhiteLocal from "../assets/icons/left-arrow-white.png"
import playButtonLocal from "../assets/icons/play-button.png"
import closeIconLocal from "../assets/icons/close-icon.png"
import letterXLocal from "../assets/icons/letter-x.svg"
import playWhiteLocal from "../assets/icons/play.png"
import pauseWhiteLocal from "../assets/icons/pause.png"
import fullScreenWhiteLocal from "../assets/icons/fullscreen-white.png"
import exitFullScreenWhiteLocal from "../assets/icons/exit-fullscreen-white.png"
import castMembersCategoryPlaceholderLocal from "../assets/cast-members-placeholder.svg"
import pictureStartLocal from "../assets/picture-start.jpg"
import scrollToTop from '../assets/scrollToTop.png'

export const homeIconWhite = homeIconWhiteLocal
export const loadingSpinnerBlackBg = loadingSpinnerBlackBgLocal
export const colorSpinner = colorSpinnerLocal
export const leftArrowWhite = leftArrowWhiteLocal
export const playButton = playButtonLocal
export const closeIcon = closeIconLocal
export const letterX = letterXLocal
export const playWhite = playWhiteLocal
export const pauseWhite = pauseWhiteLocal
export const fullScreenWhite = fullScreenWhiteLocal
export const exitFullScreenWhite = exitFullScreenWhiteLocal
export const castMembersCategoryPlaceholder = castMembersCategoryPlaceholderLocal
export const pictureStart = pictureStartLocal
export const ScrollToTop = scrollToTop
